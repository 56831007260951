.green-table {
    width: 100%;
    height: 100%;
    margin: 1rem 0rem;
    border-radius: 1.6rem;
    height: calc(100% - 8.4rem);
    background-color: var(--bg-color);;

    .table-light {
        background-color: var(--bg-color);;
    }

    .table-action-wrapper {
      width: 100%;
      margin-bottom: 2rem;

      .search-input {
        cursor: pointer;
        max-width: 20rem;
        margin-right: 1rem;

        input {
            background-color: white;
            border: 1px solid var(--border-input-primary-color);
            height: 100%;
            border-radius: 0.4rem !important;
            padding: 1.2rem 1.2rem 1.2rem 4.5rem !important;
        }
      }

      .sort-by-root,
      .datetype-picker,
      .search-type-picker {
            background-color: white;
            width: 18.2rem;
            margin-right: 1rem;
            // display: flex;
            align-items: center;
            border-radius: 0.4rem;
            cursor: pointer;
            border: 1px solid var(--border-input-primary-color);

        .dropdown {
            display: flex;
            width: inherit;
            position: relative;
            height: 100%;

            span:first-child {
                padding-left: 1.2rem;
                color: var(--text-grey);
                border-right: 1px solid var(--border-input-primary-color);
                align-self: center;
                padding: 10px;
            }

            span:nth-child(2) {
                padding-left: 0.8rem;
                color: var(--text-dark);
                font-weight: 700;
                align-self: center;
            }

            span:last-child {
                margin-left: auto;
                margin-right: 0.7rem;
            }

            .arrow-custom {
                position: absolute;
                right: 16px;
                top: 15px;
            }
        }

        .dropdown-opened {
          position: absolute;
          top: 37%;
          width: inherit;
          background-color: var(--color-white);
          z-index: 2;
          text-align: center;
          padding: 2rem;
          border-radius: 8px;
          box-shadow: var(--lighter-gray) 5px 5px;

          div {
            padding: 0.2rem;
            display: flex;
            align-items: flex-end;

            div {
              padding-left: 20%;
            }

            img {
              margin-left: auto;
              margin-right: 0;
            }
          }
          div:hover {
            background-color: var(--lighter-gray);
            border-radius: 8px;
          }
        }
      }

      .datetype-picker {
        .dropdown-opened {
          div:first-child {
            margin-top: -1rem;
          }
        }
      }

      .datepicker-container {
        margin-right: 1rem !important;
      }

      .search-type-picker {
        width: 20rem;
      }
      .datetype-picker {
        width: 27.5rem;

        .dropdown-opened {
          width: inherit !important;

          div {
            padding-top: 1rem;
          }
        }

        .arrow {
          margin-left: auto;
          margin-right: 1rem;
        }
      }



      .btn-icon {
        border: 0.2rem solid var(--primary-color);
        margin-right: 1rem;
      }

      .btn-upload {
        border-radius: 0;
        border-top-left-radius: 0.8rem;
        border-bottom-left-radius: 0.8rem;
        border-right: 1px solid;
        border-color: var(--btn-upload-border-green) !important;
        height: 100%;

        img {
          margin-right: 0;
        }
      }

      .btn-add-new {
        border-radius: 0;
        border-top-right-radius: 0.8rem;
        border-bottom-right-radius: 0.8rem;
      }

      .xlsx-upload {
        display: flex;
        position: absolute;
        z-index: 1;
        background: var(--color-white);
        align-items: flex-end;
        cursor: pointer;
        border-radius: 0.4rem;
        div:first-child {
          padding: 2.4rem 1.1rem 1.7rem 1.9rem;
        }

        div:last-child {
          padding: 1.6rem 3.4rem 1.6rem 0;
          font-weight: 700;
          line-height: 2.4rem;
        }
      }

      .xlsx-upload:hover {
        background-color: var(--lighter-gray);
      }
    }

    table {
        margin-top: 3rem;

        tbody {
            tr:nth-child(even) {
                background-color: white;
            }

            td {
                padding: 1.4rem;
                vertical-align: middle;
            }
        }

        th {
            background-color: white !important;
            color: var(--text-gray) !important;
        }

        tr {
            cursor: pointer;

            td:nth-last-child(2) {
                width: 8rem !important;
            }

            .checkbox {
                padding-left: 1rem !important;
            }
        }

        .form-check-input {
            width: 2rem;
            height: 2rem;
        }

        input[type=checkbox]:checked, input[type=checkbox] {
            background-color: transparent;
            border: 1px solid var(--text-primary);
        }

        input[type=checkbox]:checked {
            position: relative;

            &::after {
                content: "";
                width: 6px;
                height: 11px;
                border: solid black;
                border-width: 0 1px 1px 0;
                transform: rotate(45deg);
                display: block;
                left: 6px;
                position: absolute;
                top: 1px;
            }
        }

        .status-incomplete {
            background: var(--dark-orange);
            color: var(--orange-status-color);
            width: 100%;
            max-width: 10rem;
            padding: 0.2rem 0.8rem;
            border-radius: 0.4rem;
            margin: 0 auto;
            margin: auto;
        }
    }
}
@media (-webkit-device-pixel-ratio >= 1.81) and (max-width: 950px) {
    .green-table {
        table {
            display: block;
            overflow-x: auto;
        }
    }
}

@media (-webkit-device-pixel-ratio: 1) and (-webkit-device-pixel-ratio < 1.81) {
    .green-table {
        table {
            display: block;
            overflow-x: auto;
        }
    }
}
