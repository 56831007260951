.filter {
    .filter-list {
      display: flex;
      align-items: center;
      font-weight: 500;

      .label {
        padding: 10px;
        background-color: white;
        border: 1px solid var(--text-primary);
        border-radius: 0.4rem;
      }
  
      .form-select {
        margin: 0rem 0.5rem 0rem 0.5rem;
        font-size: 1.3rem;
        border-radius: 0.4rem;
        border: solid 1px var(--silver-sand);
        font-weight: 500;
      }
  
      .multiselect {
        width: 100%;
        margin: 0rem 0.5rem 0rem 0.5rem;
      }
    }
}
