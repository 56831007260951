.regular-tabs {
    background-color: var(--table-root-bg-color) !important;
    padding-top: 5px;
    border-radius: 20px;

    .separated-toggle-item {
        padding: 1rem, 1rem, 1rem, 1rem;
        background-color: var(--gray-medium);
        padding: 2.2rem;
        font-weight: 600;
        width: 25%;
        font-size: 3.6rem;
        margin: 0px 1px -16px 1px;
        border-radius: 15px 15px 0px 0px;
        text-align: center;
    }

    .separated-toggle-item:hover {
        cursor: pointer;
        color: var(--primary-color);
    }

    .bubble {
        margin: auto;
        background-color: var(--bubble-color);
        padding: 3px 10px;
        border-radius: 10px;
        color: var(--color-white);
        font-weight: 400;
        font-size: 12px;
        margin-right: 10px;
    }

    .active {
        background-color: var(--color-white) !important;
    }

    .table {
        background-color: var(--color-white);

        .align-options-end {
            text-align: end;
            padding-right: 4.4rem;
            max-width: 70px;
        }

        .transparent-button {
            margin-right: 22px;
        }

        tr:last-child {
            td {
                padding-bottom: 2rem;
            }
        }
    }

    .table-root-child {
        width: 100%;
        background-color: var(--color-white);
        margin: 1.6rem 0rem 0rem 0rem;
        padding: 2.4rem;
        border-radius: 0px;
        height: calc(100% - 8.4rem);

        table {
            tr {
                cursor: pointer;

                td:nth-last-child(2) {
                    width: 8rem !important;
                }
            }

            tr:last-child {
                td {
                    padding-bottom: 2rem;
                }
            }
        }
    }
}

.regular-tab-content {
    padding: 2.5rem;
    height: 100%;
    background-color: var(--bg-color);;

    .arrow-up {
      transform: rotate(-180deg);
    }
}
