:root {
  --primary-color: #03423e;
  --color-blue: #0052cc;
  --color-tomatoRed: #ff6347;
  --color-violet: #8f00ff;
  --color-mauve: #e072a4;
  --color-red: #de350b;
  --color-white: #fff;
  --color-black: #000000;
  --color-dark-gray: #575757;
  --gray-secondary: #707070;
  --gray-stats: #667085;
  --black-stats: #101828;

  --table-root-bg-color: #ebf1f1;
  --bubble-color: #688f8d;
  --card-color: #e4ecea;
  --bg-color: #f5fbfa;
  --bg-gray: #f4f5f6;
  --text-dark: #171717;
  --text-gray: #8f92a1;
  --border-input-primary-color: #dee1e6;
  --placeholder-primary-color: #c0c4cc;
  --primary-text-color: #474747;
  --red-mandatory: #c22427;
  --text-primary: #292929;
  --secondary-text-color: #858585;
  --background-primary: #f4f6fa;
  --mat-option-bg-color: #0000000a;
  --color-lighter-gray: #e8e8e8;
  --btn-bg-primary: #edeff3;
  --green-primary: #16a862;
  --placeholder-secondary-color: #808A9E;
  --green-secondary: #01B91E;
  --label-checkbox-color: #666875;
  --link-color: #1650A8;
  --text-secondary: #00143C;
  --soft-green: #f5fbfa;
  --input-bg: #8F92A10D;
  --lighter-gray: #f8f9fa;
  --lighter-gray-2: #F9F9FA;
  --input-error-color: #b64141;
  --dark-green: #00875A33;
  --gray-medium: #E2E8E9;
  --silver-sand: #bebcc7;
  --chinese-silver: #c9c9c9;
  --cool-grey: #8F92A133;
  --deep-saffron: #fb9f30;
  --color-cultured: #F4F4F6;
  --dark-orange: #FF991F33;
  --ghost-white: #F9FAFA;
  --grey-v2: #9091A1;
  --silver-color: #BDBDC7;
  --shamrock-color: #5CAF82;
  --green-light: #CCE7DE;
  --pale-pink: #ffd7d7;
  --bright-gray: #E4E7EC;
  --dropdown-hover-gray: #eaebeb;
  --btn-upload-border-green: #4e7b7996;
  --platinum-color: #DDE9E7;
  --slate-green: #205753;
  --blue-gray: #475467;
  --gray-1: #ebf0f1;
  --gray-2: #778CA2;
  --forest-green: #026962;
  --gray-3: #deeaff;
  --gray-4: #e9e9e9;
  --gray-5: #f7f7f7;
  --pine-green: #0a7971;
  --gray-6: #b3b3b3;
  --gray-7: #f3f3f5;
  --gray-8: #B3B9C5;
  --slider-bg: #ccc;
  --gray-9: #e1e1e1;
  --gray-10: #f3f3f3;
  --gray-11: #fffaf4;
  --gray-12: #f2f5f5;
  --gray-13: #f2f9f6;
  --gray-14: #f5f6f7;
  --gray-15: #D8DAE5;
  --silver-foil: #afafaf;
  --gray-16: #E9E9EC;
  --lava-color: #cf1414;
  --gray-17: #E6EDEC;
  --silver-black: #1b1b1b95;
  --lake-green: #053f3b;
  --gray-18: #d4d4d4;
  --sea-green: #379951;
  --sea-green-lighter: #298842;
  --ufo-green: #2ed95b;
  --white-green: #F2FAF0;
  --gray-19: #e6e6e6;
  --gray-20: #D0D5DD;
  --black-blue: #344054;
  --vegas-gold: #c9ab51;
  --gray-gold: #faf4e1;
  --apple-red: #cf0a0a;
  --gray-red: #F9F9F9;
  --gray-red-dark: #747474;
  --gray-21: #f7f7f7bd;
  --gray-22: #e4e7ec6f;

  // RGBA colors
  --rgba-black-005: rgba(0, 0, 0, 0.05);
  --rgba-black-007: rgba(42, 28, 28, 0.07);
  --rgba-black-01: rgba(0, 0, 0, 0.1);
  --rgba-black-02: rgba(0, 0, 0, 0.2);
  --rgba-black-03: rgba(0, 0, 0, 0.3);
  --rgba-black-043: rgba(0, 0, 0, 0.43);
  --rgba-black-05: rgba(0, 0, 0, 0.5);
  --rgba-black-075: rgba(0, 0, 0, 0.75);
  --rgba-black-09: rgba(0, 0, 0, 0.9);
  --rgba-black-015: rgba(0, 0, 0, 0.15);
  --rgba-gray-blue: rgba(244, 246, 250, 1);
  --rgba-black-red: rgba(41, 41, 41, 1);
  --rgba-gray-1: rgba(222, 225, 230, 1);
  --rgba-gray-2: rgba(237, 239, 243, 1);
  --rgba-gray-3: rgba(208, 210, 214, 1);
  --rgba-apple-red: rgba(194, 36, 39, 1);
  --rgba-grayBlue-005: rgba(143, 146, 161, 0.05);
  --rgba-dark-grayBlue-01: rgba(143, 146, 161, 0.1);
  --rgba-dark-grayBlue-02: rgba(143, 146, 161, 0.200);
  --rgba-dark-grayBlue-05: rgba(143, 146, 161, 0.5);
  --rgba-dark-grayBlue-08: rgba(143, 146, 161, 0.8);
  --rgba-dark-grayBlue: rgba(143, 146, 161, 0.2);
  --rgba-dark-grayBlue-2: rgba(115, 134, 169, 0.06);
  --rgba-black2-06: rgba(23, 23, 23, 0.6);
  --rgba-blue-04: rgba(0, 82, 204, 0.4);
  --rgba-darkGreen-005: rgba(0, 66, 62, 0.05);
  --rgba-darkGray-005: rgba(16, 24, 40, 0.05);
  --rgba-yellow-02: rgba(251, 255, 31, 0.2);
  --rgba-lightOrange-02: rgba(251, 159, 48, 0.2);
  --rgba-red-03: rgba(222, 53, 11, 0.3);
  --rgba-lighterBlack-015: rgba(10, 10, 10, 0.15);
  --rgba-lighterBlack-02: rgba(10, 10, 10, 0.2);
  --rgba-lighterBlack-09: rgba(10, 10, 10, 0.9);
  --rgba-blackRed-0849: rgba(19, 19, 19, 0.849);
  --rgba-grayRed-04: rgba(200, 200, 200, 0.4);
  --rgba-darkGreen-01: rgba(0, 66, 62, 0.1);
  --rgba-lightGray-056: rgba(253, 253, 253, 0.56);

  // RGB colors
  --rgb-blue: rgb(75, 38, 211);
  --rgb-gray: rgb(227, 228, 230, 0.9);


  // Box shadows
  --menu-box-shadow: 0px 4px 15px var(--rgba-black-007);

  // Statuses colors
  --orange-status-bg-color: rgba(255, 153, 31, 0.2);
  --orange-status-color: #ff991f;
  --green-status-bg-color: rgba(0, 135, 90, 0.2);
  --green-status-color: #00875A;
  --gray-status-bg-color: rgba(128, 128, 128, 0.2);
  --gray-status-color: gray;
  --red-status-bg-color: rgba(255, 0, 0, 0.2);
  --red-status-color: red;
  --darkGreen-status-bg-color: #00423e33;
  --darkGreen-status-color: #00423e;
  --darkRed-status-bg-color: #de350b4d;
  --darkRed-status-color: #920404;
  --oliveGreen-status-bg-color: #877e0033;
  --oliveGreen-status-color: #877e00;

  --usage-status-color: #188D52;
  --inaccessible-status-color: #B6910F;
  --failed-status-color: #C03438;
  --rejected-status-color: #FC6606;
  --inoperable-status-color: #8D400B;

  --usage-status-bg-color: #d4ebe1;
  --inaccessible-status-bg-color: #ddd7a6;
  --failed-status-bg-color: #e1b4b5;
  --rejected-status-bg-color: #f6c8a4;
  --inoperable-status-bg-color: #cfb8a5;
}
