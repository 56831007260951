@use "../src/styles/_variables.scss" as *;
@use "../src/styles/_buttons.scss" as *;
@use "../src/styles/_arrows.scss" as *;
@use "../src/styles/_statuses.scss" as *;
@use "../src/styles/_forms.scss" as *;
@use "../src/styles/_detail-cards.scss" as *;
@use "../src/styles/_tables.scss" as *;
@use "../src/styles/_tabs.scss" as *;
@use "../src/styles/_datepickers.scss" as *;
@use "../src/styles/_filters.scss" as *;
@use "../src/styles/_stats.scss" as *;
@use "../src/styles/_table-action-wrapper.scss" as *;

// @import url("https://fonts.googleapis.com/css2?family=DM+Sans:ital,wght@0,400;0,500;0,700;1,400;1,500;1,700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=DM+Sans:ital,opsz,wght@0,9..40,100;0,9..40,200;0,9..40,300;0,9..40,400;0,9..40,500;0,9..40,600;0,9..40,700;0,9..40,800;0,9..40,900;0,9..40,1000;1,9..40,100;1,9..40,200;1,9..40,300;1,9..40,400;1,9..40,500;1,9..40,600;1,9..40,700;1,9..40,800;1,9..40,900;1,9..40,1000&family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");
@import "@angular/material/prebuilt-themes/deeppurple-amber.css";
@import "intl-tel-input/build/css/intlTelInput.css";

* {
  box-sizing: border-box;
}

.primary-color {
  fill: var(--primary-color);
}

html,
body {
  display: flex;
  flex-direction: column;
  height: 100%;
  //This defines what 1rem is
  font-size: 62.5% !important; //1rem = 10px; 10px/16px = 62.5%
}
body {
  margin: 0;
  font-family: "DM Sans", sans-serif !important;
  font-size: 1.6rem;
  color: var(--text-dark);
}
app-root {
  flex: 1;
}
h1 {
  font-weight: 700;
  font-size: 2.2rem;
  line-height: 3rem;
  margin-bottom: 0;
}
.btn,
a,
p,
div {
  font-size: 1.6rem;
}
a {
  text-decoration: none;
  color: var(--text-dark);
}
.link {
  color: var(--primary-color);
  font-weight: 700;
  font-size: 1.3rem;
  &:hover {
    text-decoration: underline;
    color: var(--forest-green);
  }
}
.color-red {
  color: var(--color-red);
}
.color-green {
  color: var(--green-status-color);
}
.page-content {
  background-color: var(--color-white);
}
.mb-25 {
  margin-bottom: 2.5rem !important;
}
// Buttons
.btn {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0.8rem;
  padding: 0.8rem 1.2rem;
  font-weight: 700;
  font-size: 1.5rem;
  img {
    margin-right: 0.8rem;
  }
  &:active {
    border-color: transparent;
  }
}
.btn-primary {
  background-color: var(--primary-color);
  border-color: var(--primary-color) !important;
  &:hover {
    filter: brightness(1.1);
    background-color: var(--primary-color) !important;
  }
}
.btn-outline-danger {
  color: var(--color-red);
  border: 0.2rem solid var(--color-red);
}

// Cards
.card-wrapper {
  background-color: var(--bg-color);
  padding: 2.5rem;
  margin-bottom: 3.2rem;
  border: none;
}
.card-title {
  font-size: 1.6rem;
  font-weight: 700;
}
.card {
  background-color: var(--color-white);
  border-radius: 1.6rem;
  border: none;
}
// Input checkbox
input[type="checkbox"] {
  background-color: var(--gray-3);
  border: none;
  &:focus {
    box-shadow: none !important;
  }
}
input[type="checkbox"]:checked {
  background-color: var(--primary-color);
}
.form-check-input {
  width: 1.6rem;
  height: 1.6rem;
  min-width: 1.6rem;
  cursor: pointer;
}
.search-input {
  position: relative;
  width: 100%;
  max-width: 29rem;

  input {
    border: none;
    background-color: var(--ghost-white);
    padding: 1rem;
    padding-left: 4.5rem;
    height: 4rem;
    border-radius: 1.2rem;
    width: 100%;
    font-size: 1.4rem;
    font-weight: 500;
    border: 0.1rem solid var(--ghost-white);

    &:focus-visible {
      border: 0.1rem solid var(--gray-4);
      outline: none !important;
    }
    &::-webkit-input-placeholder {
      /* Edge */
      color: var(--text-gray);
    }

    &:-ms-input-placeholder {
      /* Internet Explorer */
      color: var(--text-gray);
    }

    &::placeholder {
      color: var(--text-gray);
    }
  }
  img {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 1.2rem;
  }
}
.toast-message {
  font-size: 1.4rem;
}

.dot {
  width: 1rem;
  height: 1rem;
  border-radius: 50%;
  margin-right: 0.8rem;

  &.customers {
    background-color: var(--color-tomatoRed);
  }

  &.buildings {
    background-color: var(--orange-status-color);
  }

  &.operations.customers {
    background-color: var(--color-tomatoRed);
  }

  &.surveys {
    background-color: var(--color-mauve);
  }

  &.transformers {
    background-color: var(--color-blue);
  }

  &.installations {
    background-color: var(--color-violet);
  }

  &.poles {
    background-color: var(--green-status-color);
  }
}

.agm-map-container-inner {
  border-radius: 1.5rem;
}

.filter-bar {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 3rem;
  .periods {
    margin-right: 2.4rem;

    .period-item {
      border: 0.1rem solid var(--primary-color);
      padding: 0.2rem 1.6rem;
      font-weight: 700;
      font-size: 1.3rem;
      cursor: pointer;

      &.active {
        background-color: var(--primary-color);
        color: var(--color-white);
      }
    }

    .period-item:first-child {
      border-top-left-radius: 1rem;
      border-bottom-left-radius: 1rem;
    }
    .period-item:last-child {
      border-top-right-radius: 1rem;
      border-bottom-right-radius: 1rem;
    }
  }

  .date {
    display: flex;
    justify-content: center;
    align-items: center;

    .arrow {
      display: flex;
      padding: 0.2rem 0.3rem;
      font-size: 1.3rem;
      border: 0.1rem solid var(--bubble-color);
      border-radius: 1rem;
      cursor: pointer;
    }

    .value {
      color: var(--text-grey);
      margin-left: 0.8rem;
      margin-right: 0.8rem;
    }
  }
}

.page-title {
  font-size: 2.2rem;
  font-weight: 700;
  line-height: 3rem;
}

.page-header {
  padding: 1.6rem 2.4rem;
  .btn {
    margin-left: 1.6rem;
    width: 8rem;
    min-height: 4.25rem;
  }
}

.page {
  background-color: var(--bg-color);
  height: 100%;
}

.form-wrapper {
  background-color: var(--color-white);
  text-align: center;
  h1 {
    font-weight: 700;
    font-size: 2.2rem;
    color: var(--text-dark);
    margin-bottom: 1rem;
  }
  .welcome-text {
    color: var(--text-gray);
    font-size: 1.4rem;
    font-weight: 400;
    margin-bottom: 4rem;
  }
  .form-item {
    margin-bottom: 2.5rem !important;

    select {
      height: 4.6rem;
      padding: 1rem 2.4rem;
      font-size: 1.4rem;
      font-weight: 500;
      background-color: var(--ghost-white);
      border: none;
      border-radius: 0.8rem;
      transition: 0.2s;
      color: var(--grey-v2);
      &:focus {
        outline: none;
        box-shadow: none;
        border: 1px solid var(--primary-color);
      }
      &.valid {
        color: var(--text-dark);
      }
      &.selected-option {
        color: black;
        background-color: var(--color-white);
        border: 1px solid var(--silver-sand);
      }
    }
    option:not(:first-of-type) {
      color: var(--text-dark);
    }
    input {
      &.ng-valid {
        background-color: var(--color-white);
        border: 1px solid var(--silver-sand);
        -webkit-box-shadow: 0 0 0px 1000px var(--color-white) inset;
        box-shadow: 0 0 0px 1000px var(--color-white) inset;
      }

      &.ng-invalid {
        background-color: var(--ghost-white);
        border: 1px solid var(--ghost-white);
        -webkit-box-shadow: 0 0 0px 1000px var(--ghost-white) inset;
        box-shadow: 0 0 0px 1000px var(--ghost-white) inset;
      }

      height: 4.6rem;
      padding: 1rem 2.4rem;
      font-size: 1.4rem;
      font-weight: 500;
      background-color: var(--ghost-white);
      border: none;
      border-radius: 0.8rem;
      transition: 0.2s;

      &:focus {
        outline: none;
        box-shadow: none;
        border: 1px solid var(--primary-color) !important;
        -webkit-text-fill-color: var(--text-dark);
        -webkit-box-shadow: 0 0 0px 1000px var(--color-white) inset;
        -webkit-transition: background-color 5000s ease-in-out 0s;
        transition: background-color 5000s ease-in-out 0s;
        & ~ label {
          color: var(--primary-color);
        }
      }
    }
    label {
      transition: 0.2s;
      max-width: fit-content;
      opacity: 1 !important;
      color: var(--text-gray);
      height: auto;
      padding-left: 2.4rem;
      font-size: 1.4rem;
      font-weight: 500;
      .disabled-label {
        opacity: 0.4 !important;
      }
    }

    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus {
      border: 1px solid var(--silver-color);
      -webkit-text-fill-color: var(--text-dark);
      -webkit-box-shadow: 0 0 0px 1000px var(--color-white) inset;
      -webkit-transition: background-color 5000s ease-in-out 0s;
      transition: background-color 5000s ease-in-out 0s;
      font-weight: 500 !important;
    }
  }
  .form-item:last-child {
    margin-bottom: 0 !important;
  }
  .form-group:last-child {
    .form-item {
      margin-bottom: 0 !important;
    }
  }

  .drag-drop {
    position: relative;
    margin: 0 auto;
    border: dashed 2px var(--rgba-dark-grayBlue);
    padding: 2rem;
    margin-bottom: 2.4rem;
    background-color: var(--ghost-white);
    border-radius: 2rem;

    .upload-img {
      width: 4.8rem;
      height: 4.8rem;
      background-color: var(--gray-7);
      border-radius: 2rem;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0 auto;
    }

    .browse {
      color: var(--darkGreen-status-color);
      font-weight: 700;
    }
    input {
      opacity: 0;
      position: absolute;
      z-index: 2;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      cursor: pointer;
    }

    label {
      color: var(--text-gray);
    }
  }
}

.form-floating > .form-control:-webkit-autofill ~ label {
  opacity: 0.65;
  transform: scale(0.85) translateY(-12px) translateX(20px);
  padding: 0 10px !important;
  background-color: var(--ghost-white) !important;
  font-weight: 500;
  font-family: "DM Sans", sans-serif !important;
}
.form-floating > .form-control-plaintext ~ label,
.form-floating > .form-control:focus ~ label,
.form-floating > .form-control:not(:placeholder-shown) ~ label,
.form-floating > .form-select ~ label {
  opacity: 0.65;
  transform: scale(0.85) translateY(-12px) translateX(20px);
  padding: 0 10px !important;
  background-color: var(--color-white) !important;
  font-weight: 500;
  font-family: "DM Sans", sans-serif !important;
}

.form-floating > label {
  top: 1px;
}

@media screen and (max-width: 425px) {
  .bar-item {
    display: block !important;
    font-size: 1.3rem;

    .dot {
      justify-content: center;
      align-items: center;
      margin: 0 auto;
    }
  }
}

@media (-webkit-device-pixel-ratio: 1.5) and (min-width: 725px) {
  :root,
  .anti-zoom,
  .gm-bundled-control {
    zoom: 0.666679;
  }

  .map-component,
  #chart {
    zoom: calc(1 / 0.666679) !important;
  }

  color-picker {
    zoom: calc(1 / 0.666679) !important;
  }
}
@media (-webkit-device-pixel-ratio: 1.25) and (min-width: 725px) {
  :root,
  .anti-zoom,
  .gm-bundled-control {
    zoom: 0.8;
  }

  .map-component,
  #chart {
    zoom: calc(1 / 0.8);
  }
}
@media (-webkit-device-pixel-ratio: 1.1) and (min-width: 725px) {
  :root,
  .anti-zoom,
  .gm-bundled-control {
    zoom: 0.9091;
  }

  .map-component,
  #chart {
    zoom: calc(1 / 0.9091);
  }
}
@media (-webkit-device-pixel-ratio: 1.75) and (min-width: 725px) {
  :root,
  .anti-zoom,
  .gm-bundled-control {
    zoom: 0.5713;
  }

  .map-component,
  #chart {
    zoom: calc(1 / 0.5713);
  }
}
@media (-webkit-device-pixel-ratio: 2) and (min-width: 725px) {
  :root,
  .anti-zoom,
  .gm-bundled-control {
    zoom: 0.5;
  }

  .map-component,
  #chart {
    zoom: calc(1 / 0.5);
  }

  #streetview-small {
    height: 100px !important;
    right: 35px !important;
    bottom: 25px !important;
  }
}
@media (-webkit-device-pixel-ratio: 2.5) and (min-width: 725px) {
  :root,
  .anti-zoom,
  .gm-bundled-control {
    zoom: 0.4;
  }
  .map-component,
  #chart {
    zoom: calc(1 / 0.4);
  }
}
@media (-webkit-device-pixel-ratio: 3) and (min-width: 725px) {
  :root,
  .anti-zoom,
  .gm-bundled-control {
    zoom: 0.33;
  }

  .map-component,
  #chart {
    zoom: calc(1 / 0.33);
  }
}

.table {
  thead {
    border-bottom: none;
    th {
      padding-top: 1.5rem;
      padding-bottom: 1.5rem;
      border-bottom: none;
      text-transform: uppercase;
      font-size: 1.2rem;
      font-weight: 800;
      vertical-align: middle;
      &:first-child {
        border-radius: 1.6rem 0 0 1.6rem;
      }
      &:last-child {
        border-radius: 0 1.6rem 1.6rem 0;
      }
    }
  }
  tr {
    border-bottom: none;
    td:first-child,
    th:first-child {
      padding-left: 1.4rem;
    }
    td:last-child,
    th:last-child {
      padding-right: 1.4rem;
    }
  }
  tbody {
    border-bottom: none;
    td {
      padding-top: 3rem;
      border-bottom: none;
      font-size: 1.4rem;
    }
    .status {
      padding: 0.2rem 0.8rem;
      line-height: 2.4rem;
      font-size: 1.4rem;
      font-weight: 500;
      width: fit-content;
      border-radius: 0.8rem;
      margin: auto;
      &.active {
        background-color: var(--green-light);
        color: var(--green-status-color);
      }
      &.inactive {
        color: var(--color-red);
        background-color: var(--pale-pink);
      }
    }
    .more-icon {
      text-align: right;
      div.more-icon {
        cursor: pointer;
        width: fit-content;
        margin-left: auto;
      }
      &:hover img {
        cursor: pointer;
        opacity: 1 !important;
      }
      img.more-icon {
        opacity: 0.5;
        transition: 0.2s;
      }
    }
    .dropdown-menu {
      box-shadow: 0 0.8rem 1.6rem var(--rgba-black-005);
      border-radius: 0.8rem;
      font-size: 1.4rem;
      font-weight: 700;
      padding: 1rem 0.8rem;
      border: 1px solid var(--gray-5);
      li {
        padding: 0.8rem;
        display: flex;
        align-items: center;
        cursor: pointer;
        border-radius: 0.5rem;
        &:hover {
          background-color: var(--bg-gray);
        }
      }
      img {
        margin-right: 0.8rem;
        width: 2.4rem;
      }
    }
  }
}

.modal {
  .modal-content {
    padding: 2.4rem;
    border: none;
  }

  .modal-image {
    padding: 0;
    border: none;
    background: none;
  }

  .modal-header {
    border: none;
    padding: 0;
    margin-bottom: 2.4rem;
  }

  .btn-cancel-modal {
    background: none;
    border: none;
    color: red;
  }

  .modal-title {
    font-family: "DM Sans";
    font-style: normal;
    font-weight: 700;
    font-size: 1.6rem;
    line-height: 2.2rem;
    letter-spacing: -0.4px;
    color: var(--text-dark);
  }

  .modal-body {
    display: flex;
    flex-direction: column;
    padding: 0;
    margin-bottom: 2.4rem;

    .info-label {
      font-size: 12px;
      color: var(--text-gray);
    }

    p {
      text-align: center;
    }
    textarea {
      width: 40rem;
      margin-left: 25px;
      margin-top: 2.3rem;
      height: 10rem;
    }

    .textarea-field {
      width: 100%;
      height: 14rem;
      border-radius: 0.8rem;
      border: 1px solid var(--text-gray);
      margin-left: 0px;
      max-height: 14rem;
      min-height: 14rem;
      padding: 1rem;
    }

    .text-area-label {
      max-width: max-content;
      background: white;
      left: 6px;
      color: var(--placeholder-secondary-color);
      padding-bottom: 0px;
    }

    input {
      padding: 1.1rem 2.4rem;
      border-radius: 0.8rem;
      background: var(--rgba-grayBlue-005);
      border: none;
    }

    input:nth-child(even) {
      margin-top: 2.4rem;
    }

    input[type="text"]::-webkit-input-placeholder {
      color: var(--text-grey);
      letter-spacing: -0.2px;
      font-family: "DM Sans";
      font-style: normal;
      font-size: 14px;
      font-weight: 500;
      line-height: 25px;
    }

    input:focus-visible {
      border: none;
      outline: none;
    }

    .error {
      font-family: "DM Sans";
      font-style: normal;
      font-weight: 400;
      font-size: 1.4rem;
      line-height: 2rem;
      letter-spacing: -0.4px;
      color: var(--color-red);
      padding: 0.5rem 2.4rem;
    }
  }

  .modal-body-image {
    margin: 0;
    display: flex;

    img {
      border-radius: 1rem;
    }

    svg {
      position: absolute;
      z-index: 1;
      right: 0;
      margin-right: 2rem;
      margin-top: 1.8rem;
      width: 1.6rem;
      height: 1.6rem;
    }

    svg:hover {
      cursor: pointer;
      path {
        fill: red;
      }
    }

    // img {
    //   position: relative;
    //   max-height: 90vh;
    //   object-fit: contain;
    //   max-width: 90vw;
    // }

    lib-ngx-image-zoom {
      .ngxImageZoomContainer {
        max-width: 100%;
        max-height: 80vh;
        overflow: hidden;
        width: unset !important;
        height: unset !important;
        img {
          position: relative;
          object-fit: contain;
          width: 100%;
          height: auto;
          margin: 0 auto;
        }
      }
    }
  }

  .modal-footer {
    justify-content: center;
    border: none;
    padding: 0;
    button {
      width: 27rem;
    }

    &.updateStatus {
      justify-content: center !important;
      display: flex;
      align-items: baseline;

      button {
        width: 20rem !important;
        &.approve {
          background-color: var(--pine-green);
        }
      }
    }
  }
}

.clickable {
  cursor: pointer;
}
.w-100 {
  max-width: 100%;

  canvas {
    width: 100% !important;
    height: auto !important;
  }
}
.uploads {
  .success-bar {
    display: flex;
    align-items: center;

    .bar {
      width: inherit;
    }

    .numbers {
      margin-left: 1.6rem;
      font-size: 1.4rem;
      font-weight: 400;
    }
  }

  .status-type {
    display: flex;
    align-items: center;
    border-radius: 2rem;
    width: 40%;
    padding: 0.3rem 0rem;

    &.completed {
      background-color: var(--green-status-bg-color);
      color: var(--green-status-color);
    }

    &.processing {
      background-color: var(--rgba-lightOrange-02);
      color: var(--orange-status-color);
    }
  }

  .dot {
    width: 0.53rem;
    height: 0.53rem;
    border-radius: 50%;
    margin-right: 1.1rem;
    margin-left: 1.1rem;

    &.completed {
      background-color: var(--green-status-color);
    }

    &.processing {
      background-color: var(--orange-status-color);
    }
  }
}

select {
  display: block;
  height: 4.6rem;
  padding: 0.8rem 4.2rem 0.8rem 1.2rem;
  font-size: 1.5rem;
  background-color: var(--ghost-white);
  border: none;
  border-radius: 0.8rem;
  transition: 0.2s;
  color: var(--text-dark);
  font-weight: 700;
  background-image: url("assets/icons/gray-arrow-down.svg");
  background-repeat: no-repeat;
  background-position: right 2rem center;
  appearance: none;

  &:focus {
    outline: none;
    box-shadow: none;
    border: 1px solid var(--primary-color);
  }
}
.modal-backdrop {
  --bs-backdrop-zindex: 1050;
  --bs-backdrop-bg: var(--color-black);
  --bs-backdrop-opacity: 0.5;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: var(--bs-backdrop-zindex);
  background-color: var(--bs-backdrop-bg);
  width: unset;
  height: unset;
}

// ===== CRUD PAGES BEGIN ======
.title {
  display: flex;
  justify-content: space-between;
  padding: 2.4rem;

  p {
    font-weight: 700;
    font-size: 1.6rem;
    line-height: 2.2rem;
    font-style: normal;
    font-family: "DM Sans";
  }

  button {
    background: var(--primary-color);
    color: var(--color-white);
    padding: 0.8rem 1.2rem;
    border-radius: 0.8rem;
    height: 100%;
    max-height: 4rem;
    text-align: center;
    border: none;
    font-family: "DM Sans";
    font-style: normal;
    font-weight: 700;
    font-size: 1.4rem;
    line-height: 2.4rem;
    letter-spacing: -0.2px;
    margin-top: -1rem;
    svg {
      margin-right: 1.2rem;
      margin-bottom: 0.3rem;
    }

    &.btn:focus-visible {
      background-color: var(--primary-color) !important;
      box-shadow: 0 0 0 0.25rem var(--primary-color) !important;
    }
  }
}

.container-fluid {
  table {
    width: 100%;
    text-align: center;

    th {
      font-family: "DM Sans";
      font-style: normal;
      font-weight: 700;
      font-size: 1.3rem;
      line-height: 2rem;
      color: var(--text-dark);
      padding: 1.2rem 0.8rem;
      background: var(--rgba-grayBlue-005);

      &:first-child {
        border-top-left-radius: 1.6rem;
        border-bottom-left-radius: 1.6rem;
      }
      &:last-child {
        border-top-right-radius: 1.6rem;
        border-bottom-right-radius: 1.6rem;
      }
    }

    .content-wrapper {
      cursor: pointer;
      &:hover {
        td {
          background: var(--rgba-grayBlue-005);

          &:first-child {
            border-top-left-radius: 1.6rem;
            border-bottom-left-radius: 1.6rem;
          }
          &:last-child {
            border-top-right-radius: 1.6rem;
            border-bottom-right-radius: 1.6rem;
          }
        }
      }

      td {
        font-family: "DM Sans";
        font-style: normal;
        font-weight: 400;
        font-size: 1.4rem;
        line-height: 2.4rem;
        letter-spacing: -0.2px;
        color: var(--text-dark);
        padding: 2.5rem;
      }

      .td-svg {
        text-align: right;
        svg path {
          transition: 0.12s;
        }
        svg:hover path {
          opacity: 0.5;
        }
      }
    }
  }
}

.delete-modal-header {
  display: flex;
  justify-content: center;
}
// ===== CRUD PAGES END ======

.location {
  cursor: pointer;
  height: 40px;
  margin-top: 12px;
}

.locationText {
  text-align: end;
  margin-top: 15px;
}

.datepicker-container {
  position: relative;
  margin-right: 1.6rem;
  label {
    position: absolute;
    top: 7.5px;
    left: 1.4rem;
  }
  .right-label {
    left: 3.4rem;
  }
}

.input-color {
  width: 105%;
  height: 100%;
  position: absolute;
  left: 0;
  opacity: 0;
}

.utility-list-table {
  td {
    vertical-align: middle;
  }
}

.login-header {
  width: 100%;
  display: flex;
  padding-top: 20px;
  height: fit-content;

  .header-logo {
    padding-left: 60px;
    img {
      height: 55px;
    }
  }

  .header-x {
    margin: auto;
    margin-right: 40px;
    cursor: pointer;
  }
}

.div-center {
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 100px);
}

.login-container {
  background: var(--background-primary);
  height: 100%;
}

.login-form {
  height: fit-content;
  // background-color: red;
  margin: auto;
  padding: 20px;
  .login-title {
    font-weight: 700;
    font-size: 24px;
    text-align: center;
    width: 100%;
    margin-bottom: 45px;
  }
}

@media only screen and (max-width: 600px) {
  .header-logo {
    display: none;
  }

  .header-x {
    margin: auto;
    margin-right: 20px !important;
    img {
      width: 10px;
    }
  }
}

.iti {
  display: block !important;
}
.iti--separate-dial-code.iti--show-flags .iti__selected-dial-code {
  margin-left: 6px;
  font-size: 1.2rem;
}

.form-control-error {
  color: red;
  font-size: 12px;
  padding-top: 4px;
}

#streetview-small {
  position: absolute;
  visibility: visible;
  height: 125px;
  width: 180px;
  background-color: white !important;
  bottom: 23px;
  right: 60px;
  z-index: 4;

  &.hide {
    visibility: hidden;
  }

  .text {
    font-size: 12px;
    color: black;
    text-align: center;
    margin-top: 20%;
    font-family: "Inter";
    color: var(--text-gray);
  }

  .x-container {
    color: var(--text-gray);
    position: absolute;
    right: 4px;
    top: 0px;
    cursor: pointer;

    img {
      height: 13px;
    }
  }
}

.recall-div {
  width: 16%;
  .recall-button {
    width: 85%;
  }

  .recall-span {
    padding-right: 1rem;
    padding-left: 0.5rem;
  }

  .recall-options {
    border-radius: 0.8rem;
    padding: 1rem;
    margin-right: 1rem;
    margin-top: 0.1rem;
    z-index: 1;
    position: absolute;
    background-color: white;
    border: 1px solid lightgray;
    .recall-type {
      padding: 1rem;
      cursor: pointer;
    }

    .recall-type-enabled:hover {
      background-color: var(--rgba-gray-2);
      border-radius: 5px;
    }

    .recall-type-disabled {
      color: var(--gray-6);
      cursor: not-allowed;
    }
  }
}
.offcanvas-backdrop {
  width: 100% !important;
  height: 100% !important;
}