.arrow-custom {
  border: solid var(--text-primary);
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 3px;
  height: 11px;
  width: 11px;

  &.--small {
    height: 9px;
    width: 9px;
  }

  &.--xsmall {
    height: 4px;
    width: 4px;
  }

  &.right {
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
  }

  &.left {
    transform: rotate(135deg);
    -webkit-transform: rotate(135deg);
  }

  &.up {
    transform: rotate(-135deg);
    -webkit-transform: rotate(-135deg);
  }

  &.down {
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
  }
}
