.table-action-wrapper {
    width: 100%;
    margin-bottom: 2rem;

    .search-input {
        cursor: pointer;
    }

    .sort-by-root,
    .datetype-picker,
    .search-type-picker
     {
        background-color: var(--input-bg);
        margin-right: 1.6rem;
        display: flex;
        align-items: center;
        border-radius: .8rem;
        padding-left: 1rem;
        padding-bottom: 0.5rem;
        cursor: pointer;
        position: relative;

        span {
            font-weight: bold;
            font-family: 'Inter';
            color: var(--color-dark-gray);
        }

        button{
            margin-top: 10px;
            margin-left: 10px;
            height: 3.7rem;
            width: 9rem;
        }

        input[type="date"] {
            align-items: center;
            display: inline-flex;
            font-family: monospace;
            padding-inline-start: 1px;
            cursor: default;
            overflow: hidden;
            padding: 0px;
            border: none;
            background-color: var(--input-bg);
            cursor: pointer;
        }

        .dropdown {
            display: flex;
            align-items: center;

            span:first-child {
                padding-left: 1.2rem;
                color: var(--text-gray);
                font-size: 1.4rem;
            }

            span:nth-child(2) {
                padding-left: .8rem;
                color: var(--text-dark);
                font-weight: 700;
            }

            span:last-child {
                padding-left: 1.8rem;

                img {
                    width: 1.2rem;
                    height: .6rem;
                }
            }

            .filter-status {
                padding-left: 1.8rem !important;
            }

            .arrow {
                margin-left: auto;
                margin-right: 1rem;
            }
        }

        .dropdown-opened {
            position: absolute;
            top: 4rem;
            width: inherit;
            background-color: var(--color-white);
            z-index: 3;
            text-align: center;
            padding: 2rem;
            border-radius: 8px;
            box-shadow: var(--lighter-gray) 5px 5px;

            div {
                padding: .2rem;
            }

            div:hover {
                background-color: var(--lighter-gray);
                border-radius: 8px;
            }

            div:first-child {
                margin-top: -1rem;
            }

            &.v-2 {
                div {
                    padding: 0.2rem;
                    display: flex;
                    align-items: flex-end;
        
                    div {
                      padding-left: 20%;
                    }
        
                    img {
                      margin-left: auto;
                      margin-right: 0;
                    }
                  }
            }
        }
    }

    .datepicker-icon {
        background-color: var(--lighter-gray-2);
        width: 100%;
        padding-left: 4rem;
        margin-right: 1.6rem;
    }

    .datepicker-icon-error {
        background-color: var(--lighter-gray-2);
        width: 100%;
        padding-left: 4rem;
        border: 0.2rem solid var(--input-error-color);
        margin-right: 1.6rem;
    }

    .btn-icon {
        border: 0.2rem solid var(--primary-color);
        margin-right: 1.6rem;
    }

    .btn-upload {
        border-radius: 0;
        border-top-left-radius: .8rem;
        border-bottom-left-radius: .8rem;
        border-right: 1px solid;
        border-color: var(--btn-upload-border-green) !important;
        height: 100%;
        
        img {
            margin-right: 0;
        }
    }

    .btn-add-new {
        border-radius: 0;
        border-top-right-radius: .8rem;
        border-bottom-right-radius: .8rem;
    }

    .xlsx-upload {
        display: flex;
        position: absolute;
        z-index: 1;
        background: var(--color-white);
        align-items: flex-end;
        cursor: pointer;
        border-radius: 0.8rem;

        div:first-child {
            padding: 2.4rem 1.1rem 1.7rem 1.9rem;
        }

        div:last-child {
            padding: 1.6rem 3.4rem 1.6rem 0;
            font-weight: 700;
            font-size: 1.4rem;
            line-height: 2.4rem;
        }
    }

    .xlsx-upload:hover {
        background-color: var(--lighter-gray);
    }

    .right {
        display: flex;
        flex-direction: row;
    }
}
