.datepicker-box {
    display: grid;
    grid-template-columns: auto auto;
    background-color: white;
    border: 1px solid var(--border-input-primary-color);
    border-radius: 0.4rem;
    position: relative;

    .arrow-custom {
        position: absolute;
        right: 16px;
        top: 15px;
        cursor: pointer;
        z-index: 2;
    }

    label {
        align-self: center;
        padding: 10px;
        border-right: 1px solid var(--border-input-primary-color);
        color: var(--text-gray);
    }

    .datepicker-input {
        background-color: white;
        width: 100%;
        padding-left: 1rem;
        // border: 0.2rem solid var(--primary-color);
        margin-right: 1rem;
        border: none;
        border-radius: 0.4rem;
        position: relative;
        cursor: pointer;
    }
    
    .datepicker-input-error {
        background-color: white;
        width: 100%;
        padding-left: 4rem;
        border: 0.2rem solid var(--input-error-color);
        margin-right: 1rem;
    }

    input[type="date"]::-webkit-inner-spin-button,
    input[type="date"]::-webkit-calendar-picker-indicator {
        cursor: pointer;
        opacity: 0;
    }

    &.space-right {
        margin-right: 1rem;
    }
}