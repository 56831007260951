.card-box {
    margin: 3rem 0;
    padding: 2.6rem 2.4rem;
    height: fit-content;
    background-color: var(--color-white);
    border-radius: 1.6rem;
    border: none;

    .card-title {
        display: grid;
        grid-template-columns: max-content auto;
        grid-gap: 14px;
        padding-bottom: 3rem;

        .text {
            color: var(--primary-color);
        }

        .line {
            border-bottom: 1px solid var(--placeholder-secondary-color);
            margin-bottom: 10px;
        }
    }

    .section-label {
        font-weight: 700;
        color: black;
        padding-bottom: 2rem;
    }

    .row {
        padding-bottom: 2rem;
        text-overflow: ellipsis;

        div:first-child {
            display: flex;
            align-items: center;
            font-size: 1.2rem;
            line-height: 1.8rem;
            color: var(--text-grey);
        }

        div:last-child {
            text-align: end;
            font-weight: 400;
            font-size: 1.6rem;
            line-height: 2.2rem;
        }
    }

    .row:last-child {
        padding-bottom: 0;
    }

    .images-list {
        display: flex;

        div {
            margin-right: 2rem;
        }
    }

    .title-list {
        display: flex;

        div {
            margin-right: 7.0rem;
        }
    }

    img {
        width: 10rem;
        height: 7.6rem;
    }

    img:hover {
        cursor: pointer;
        border: solid 1px var(--bg-color);
    }
}