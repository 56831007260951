.dynamic-form-box {
    max-width: 60rem;
    height: auto;
    background-color: var(--color-white);
    border-radius: 1.6rem;
    border: none;
    padding: 2.5rem;

    .dynamic-form {
        .item-box {
            margin-bottom: 2.5rem !important;
    
            select {
                height: 4.6rem;
                padding: 1rem 2.4rem;
                font-size: 1.4rem;
                font-weight: 500;
                border-radius: .8rem;
                transition: .2s;
                color: var(--grey-v2);
                background-color: var(--ghost-white);
                border: none;

                &:focus {
                    outline: none;
                    box-shadow: none;
                    border: 1px solid var(--primary-color);
                }
                &.valid {
                    color: var(--text-dark);
                }
            }
    
            option:not(:first-of-type) {
                color: var(--text-dark);
            }
    
            input {
                &.ng-valid {
                background-color: var(--color-white);
                border: 1px solid var(--silver-sand);
                -webkit-box-shadow: 0 0 0px 1000px var(--color-white) inset;
                box-shadow: 0 0 0px 1000px var(--color-white) inset;
                }
                height: 4.6rem;
                padding: 1rem 2.4rem;
                font-size: 1.4rem;
                font-weight: 500;
                background-color: var(--ghost-white);
                border: none;
                border-radius: .8rem;
                transition: .2s;
        
                &:focus {
                outline: none;
                box-shadow: none;
                border: 1px solid var(--primary-color) !important;
                -webkit-text-fill-color: var(--text-dark);
                -webkit-box-shadow: 0 0 0px 1000px var(--color-white) inset;
                -webkit-transition: background-color 5000s ease-in-out 0s;
                transition: background-color 5000s ease-in-out 0s;
                &~label {
                    color: var(--primary-color);
                }
                }
            }
            label {
                transition: .2s;
                max-width: fit-content;
                opacity: 1 !important;
                color: var(--text-gray);
                height: auto;
                padding-left: 2.4rem;
                font-size: 1.4rem;    
                font-weight: 500;
            }
        
            input:-webkit-autofill,
            input:-webkit-autofill:hover, 
            input:-webkit-autofill:focus {
                border: 1px solid var(--silver-color);
                -webkit-text-fill-color: var(--text-dark);
                -webkit-box-shadow: 0 0 0px 1000px var(--color-white) inset;
                -webkit-transition: background-color 5000s ease-in-out 0s;
                transition: background-color 5000s ease-in-out 0s;
                font-weight: 500 !important;
            }
        }
    }

    .form-floating>.form-control:-webkit-autofill~label {
        opacity: 0.65;
        transform: scale(0.85) translateY(-12px) translateX(20px);
        padding: 0 10px !important;
        background-color: var(--ghost-white) !important;
        font-weight: 500;
        font-family: "DM Sans", sans-serif !important;
    }

    .form-floating>.form-control-plaintext~label, .form-floating>.form-control:focus~label, .form-floating>.form-control:not(:placeholder-shown)~label, .form-floating>.form-select~label {
        opacity: 0.65;
        transform: scale(0.85) translateY(-12px) translateX(20px);
        padding: 0 10px !important;
        background-color: var(--color-white) !important; 
        font-weight: 500;
        font-family: "DM Sans", sans-serif !important;
    }
    
    .form-floating>label {
        top: 1px;
    }
}
