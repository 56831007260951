.stats-root {
    display: flex;
    justify-content: space-between;
    height: 7rem;

    .stats-item {
        background-color: var(--color-white);
        width: 100%;
        padding: 0.8rem 1.6rem;
        border-radius: 0.8rem;
        margin-right: 2.4rem;
        cursor: pointer;
        transition: 0.2s;
        border: 2px solid transparent;
        display: flex;
        flex-direction: column;
        justify-content: center;

        &:hover {
            border: 2px solid var(--primary-color);
        }

        &.active {
            border: 2px solid var(--primary-color);
        }

        div:first-child {
            color: var(--gray-stats);
            font-weight: 500;
            line-height: 1.8rem;
            font-size: 1.4rem;
        }

        div:last-child {
            color: var(--black-stats);
            font-weight: 700;
            font-size: 2.4rem;
            line-height: 3.1rem;
            font-size: 2.4rem;
            margin-top: 0.4rem;
            display: inline-block;
        }

        .regularized-tab {
            position: relative;
        }

        .regularized {
            padding-left: 0 !important;
        }

        .regularized-title {
            display: flex;
            justify-content: space-between;
    
            svg {
              fill: var(--gray-stats);
              opacity: 0.5;
            }
            .custom-tooltip {
              position: absolute;
              font-size: 1.3rem;
              background: var(--lighter-gray);
              padding: 1rem;
              border-radius: 1rem;
              display: none;
              flex-direction: column;
              transform: translate(5rem, 2rem);
              box-shadow: lightgray 0px 1px 0px 1px;
    
              div {
                font-weight: bold;
                color: black;
                font-size: 1.4rem;
              }
            }
        }

        .regularized-div {
            display: flex;
            justify-content: space-between;

            span {
                color: var(--black-stats);
                font-weight: 700;
                font-size: 1.4rem;
                line-height: 3.1rem;
                margin-top: 0.4rem;
            }
        }
    }

    .stats-item:last-child {
        margin-right: 0;
    }
}

.total-stats-root {
    width: 20.5%;
    z-index: 3;
    top: 19rem;
    left: 27rem;
    background-color: var(--bg-gray);
    height: 18rem;
    margin-left: 0.5rem;
    position: absolute;
    border-radius: 1rem;
    box-shadow: var(--rgba-grayRed-04) 3px 4px;

    .regularized-extra-info {
      width: 100%;
      margin-top: 1rem;
    }

    .revenue {
      vertical-align: bottom;
    }
}

.regularized-stats-root {
    width: 16.5%;
    z-index: 3;
    top: 28rem;
    left: 27rem;
    background-color: var(--bg-gray);
    height: 18rem;
    margin-left: 0.5rem;
    position: absolute;
    border-radius: 1rem;
    box-shadow: var(--rgba-grayRed-04) 3px 4px;
    .regularized-extra-info {
      width: 100%;
      margin-top: 1rem;
    }

    .currency {
      vertical-align: bottom;
      font-size: 10px;
    }

    .revenue {
      vertical-align: bottom;
    }

    .paying-customers {
      font-size: 12px;
      padding-left: 0.5rem;
    }
}
