.buttons-container {
  display: flex;

  &.bill {
    padding: 1.6rem 2.5rem;
  }

  &.space-between {
    justify-content: space-between;
  }

  &.align-right {
    :first-child {
      margin-left: auto !important;
    }
  }

  &.align-center {
    align-items: center;
    justify-content: center;
  }

  .equal-width {
    flex: 1;
  }
}

.button {
  font-family: 'Inter';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  text-align: center;
  color: var(--text-primary);
  border: none;
  padding: 10px;
  height: max-content;
  position: relative;

  .button-dropdown {
    position: absolute;
    z-index: 1;
    background: var(--color-white);
    align-items: flex-end;
    cursor: pointer;
    border-radius: 0.8rem;
    min-width: max-content;
    right: 0;
    top: 4.6rem;

    .button-dropdown-item {
        padding: 1.2rem 1rem;
        text-align: left;

        .dropdown-icon {
            padding-right: 10px;
        }

        .dropdown-text {
            font-weight: 700;
            font-size: 1.4rem;
            line-height: 2.4rem;
            color: black;
        }

        &:hover {
            background-color: var(--lighter-gray);
        }
    }
  }

  .dropdown-apply {
    border-radius: 1.5rem;
    padding: 1rem 1.5rem;
    z-index: 1;
    position: absolute;
    background-color: white;
    border: 1px solid lightgray;
    right: 0;
    top: 4.5rem;

    .asset-grouped {
        padding: 1rem;
        cursor: pointer;

        .asset-name {
            margin-left: 0.2rem;
            color: var(--rgba-blackRed-0849);
            font-weight: 500;
            font-size: 1.6rem;
        }
    }

    .btn-apply {
        border-radius: 0.8;
        width: 200px;
        margin-top: 0.5rem;
    }

    .selected-download {
        border: 1px solid lightgray;
        border-radius: 0.8rem;
    }
  }

  &.button-with-arrow {
    padding: 10px 34px;

    .arrow-custom {
        right: 14px;
        position: absolute;
        top: 15px;
    }
  }

  &:disabled {
    opacity: 0.7;
  }

  &.full-width {
    width: 100%;
  }

  &.primary {
    border-radius: 8px;
    background: var(--btn-bg-primary);
  }

  &.primary--black {
    border-radius: 8px;
    background: var(--text-primary);
    color: var(--color-white);
  }

  &.primary--white {
    border-radius: 8px;
    border: 1px solid var(--color-lighter-gray);
    background: white;
  }

  &.left-gap {
    margin-left: 13px;
  }

  &.secondary {
    border-radius: 25px;
  }

  &.secondary--green {
    border-radius: 25px;
    background-color: var(--green-primary);
    color: white;
  }

  &.outline--green {
    border: 0.2rem solid var(--primary-color);
    color: var(--primary-color);
    border-radius: 0.8rem;
    font-weight: 700;
    font-size: 1.5rem;
    background-color: transparent;

    .icon {
        padding-left: 2rem;
    }
  }
  &.outline--red {
    border: 0.2rem solid red;
    color: red;
    border-radius: 0.8rem;
    font-weight: 700;
    font-size: 1.5rem;
    background-color: transparent;

    .icon {
        padding-left: 2rem;
    }
  }

  &.four-radius {
    border-radius: 0.4rem;
  }
}

// For Account Information Pages (After Personal Information)
.information-button{
  display: flex;
  position: absolute;
  bottom: 5rem;
  right: 34rem;

  background-color: white;
  border-top: solid 0.1rem var(--rgba-gray-1);
  
  button{
      border-radius: 0.8rem;
      padding: 1rem 7rem;
      background-color: var(--rgba-black-red);
      color: white;
      border: solid 0.2rem black;

      &:hover{
          transition: all 0.5s;
          background-color: white;
          color: black;
      }
  }
}

@media only screen and (max-width: 650px) {
  .information-button{
    display: flex;
    justify-content: center;
    align-items: center;
    position: sticky;
    bottom: 0;
    
    height: 8rem;
    background-color: white;
    border-top: solid 0.1rem var(--rgba-gray-1);
    
    button{
        width: 86%;
        height: 5rem;
        font-size: 1.8rem;
    }
}
}

.transparent-button {
    border-radius: 6px;
    background-color: transparent;
    border: 1px solid var(--bs-border-color);
    padding: 6px;
}

.double-action-button {
    max-height: 49px;
    display: grid;
    grid-template-columns: 42px 153px;

    button {
        min-height: 49px;
    }
}
