.orange-status {
    background: var(--orange-status-bg-color);
    color: var(--orange-status-color);
    padding: 0.2rem 0.8rem;
    border-radius: 0.8rem;

    &.v-2 {
        width: max-content;
        margin: auto;
    }
}

.green-status {
    background: var(--green-status-bg-color);
    color: var(--green-status-color);
    padding: 0.2rem 0.8rem;
    border-radius: 0.8rem;

    &.v-2 {
        width: max-content;
        margin: auto;
    }
}

.gray-status {
    background: var(--gray-status-bg-color);
    color: var(--gray-status-color);
    padding: 0.2rem 0.8rem;
    border-radius: 0.8rem;

    &.v-2 {
        width: max-content;
        margin: auto;
    }
}
.red-status {
    background: var(--red-status-bg-color);
    color: var(--red-status-color);
    padding: 0.2rem 0.8rem;
    border-radius: 0.8rem;

    &.v-2 {
        width: max-content;
        margin: auto;
    }
}

.dark-green-status {
    background: var(--darkGreen-status-bg-color);
    color: var(--darkGreen-status-color);
    padding: 0.2rem 0.8rem;
    border-radius: 0.8rem;

    &.v-2 {
        width: max-content;
        margin: auto;
    }
}

.dark-red-status {
    background: var(--darkRed-status-bg-color);
    color: var(--darkRed-status-color);
    padding: 0.2rem 0.8rem;
    border-radius: 0.8rem;

    &.v-2 {
        width: max-content;
        margin: auto;
    }
}

.olive-green-status {
    background: var(--oliveGreen-status-bg-color);
    color: var(--oliveGreen-status-color);
    padding: 0.2rem 0.8rem;
    border-radius: 0.8rem;

    &.v-2 {
        width: max-content;
        margin: auto;
    }
}